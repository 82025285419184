import { createClient } from '@supabase/supabase-js'
import userDataStore, { updateUserData } from "../stores/userInfo"
import { f7 } from 'framework7-svelte';
import { initPlanForUser, fetchUserPlan, getUserInvitations } from '../stores/mealPlan';
import { getUserPreferences } from '../stores/preferences';
import { getUserData } from '../stores/userInfo'
import { nanoid } from 'nanoid';
import { initializePushNotifications } from '../js/pushNotifications'

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;
const frontendURL = import.meta.env.VITE_FRONTEND_URL;

export async function loginUser({email, password, suppressErrorDialogs} = {}){
    let { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password
    })

    if (error && error.code == "email_not_confirmed"){
        if(!suppressErrorDialogs){
            f7.dialog.alert(`Your email is not confirmed. Please check your email for a confirmation link.`);
        }
        return { error }
    } else if(error && error.code == "invalid_credentials"){
        if(!suppressErrorDialogs){
            f7.dialog.alert(`Invalid email or password. Please check your email and password and try again.`);
        }
        return { error }
    } else if(!data || data.user == null){
        if(!suppressErrorDialogs){
            f7.dialog.alert(`There was an issue logging in. ${error.message}`);
        }
        return { error }
    } else {
        f7.loginScreen.close()
        return { data };
    }
}

export async function logoutUser(){
    const { error } = await supabase.auth.signOut();
}

export async function resetPassword({email} = {}){
    const { data, error } = await supabase.auth.resetPasswordForEmail(email, { redirectTo: `${frontendURL}?redirect=password-reset` });

    if(error){
        debugger
        f7.dialog.alert(`There was an error sending your password reset email. ${error}`);
    } else {
        f7.dialog.alert(`If you have an account with us you will receive an email.`);
    }
}

export async function initUser(){
    // check if there is a user logged in yet
    const { data: { user } } = await supabase.auth.getUser();

    if(!user){
        const localStorageItemId = supabase.storageKey
        
        localStorage.removeItem('userData')
        localStorage.removeItem(localStorageItemId)
        userDataStore.set({});
        f7.loginScreen.open('#my-login-screen')
    } else {
        // Get and set current user profile store locally
        let userProfile = await supabase
        .from('profiles')
        .select(`
            *,
            milestones(*)
        `)
        .eq('id', user.id);

        console.log(userProfile)

        if(userProfile.data.length == 0){
            await createProfile();
            return // Create profile re-runs the initUser function, so ending execution if we got here
        }

        updateUserData({data : {...user, ...userProfile.data[0]}})

        let completeUserData = await getUserData()

        // Check to see if this user has first/last name set in their metadata field from registration, but it was not set in profile
        if(
            (!completeUserData.first_name && completeUserData.user_metadata.first_name)
            ||
            (!completeUserData.last_name && completeUserData.user_metadata.last_name)
        ){
            // both First and Last name have not been set in the user's profile, but it has been set in registration metadata
            await updateUserName(completeUserData.user_metadata.first_name, completeUserData.user_metadata.last_name)
        }

        if(window.cordova && window.UXCam && window.UXCam.setUserIdentity){
            // Set the user details in UXCam
            window.UXCam.setUserIdentity(completeUserData.id);
        }

        // Listen for database changes to user profile
        /*
        supabase
        .channel('any')
        .on('postgres_changes', { event: '*', schema: 'public', table: 'profiles' }, payload => {
            updateUserData({data : {...user, ...payload.new}})
        })
        .subscribe()
        */

        // TODO: Check to see if the user has set any profile preferences yet
        let userPreferences;
        try {
            userPreferences = await getUserPreferences();
        } catch(error){
            alert('ERROR: initUser Unable to get profile preferences.');
        }

        if(!userPreferences || userPreferences.length == 0){
            // User does not have any preferences set yet. Show the questions collection popup
            if(f7.$('.user-preference-questions').length == 0){
                f7.views.current.router.navigate('/user-preference-questions/')
            }
        }

        // Get and listen if there are any invitation changes
        await getUserInvitations();

        // Initialize push notifications
        await initializePushNotifications();

        // Get the user's current location
        try {
            await getUserLocation();
        } catch(error){
            console.error('Error getting user location', error)
        }
        
    }
}

export async function createUser({email, password, firstName, lastName} = {}) {
    if(!email || !password || !firstName || !lastName){
        throw new Error('All fields are required.');
    }

    const { data, error } = await supabase.auth.signUp({
        email : email,
        password : password,
        options: {
            data : {
                first_name : firstName,
                last_name : lastName,
                username: email
            },
            emailRedirectTo: `${frontendURL}/signup.html?method=email_verified`
        }
    });

    

    if(!data || data.user == null || error){
        throw new Error(`There was an error creating your account. ${error}`)
    }
}

export async function createProfile({firstName = null, lastName = null} = {}) {
    const userData = await getUserData();
    const id = userData.id
    const computedFirstName = firstName ? firstName : localStorage.getItem('first_name') || '';
    const computedLastName = lastName ? lastName : localStorage.getItem('last_name') || '';

    const newProfile = {
        id: id,
        first_name : computedFirstName,
        last_name : computedLastName,
        email: userData.email
    }

    const { error: insertError } = await supabase
        .from('profiles')
        .insert(newProfile);
    if(insertError){
        throw new Error({
            errorMessage : `There was an error creating your account. ${insertError}`,
            insertError
        })
    } else {
        await initUser();
        f7.loginScreen.close()
    }
}

export async function updatePassword(password){

    const { data, error } = await supabase.auth.updateUser({
        password: password
    });

    if (error) {
        f7.dialog.alert(`There was an error updating your password. ${error}`);
    } else {
        f7.views.current.router.back();
    }
}

export async function updateUserEmail(email){
    const userData = await getUserData();
    const previousEmail = userData.email;

    const { data: updateProfileData, error: updateProfileError } = await supabase.from('profiles').update({
        email
    }).eq('id: ', userData.id);

    if (updateProfileError) {        
        f7.dialog.alert(`There was an error updating your email. ${JSON.stringify(error)}`);
        return;
    }

    const { data, error } = await supabase
    .auth
    .updateUser({
        email: email,
        first_name: userData.first_name,
        last_name: userData.last_name
    });

    if (error) {
        f7.dialog.alert(`There was an error updating your email. ${JSON.stringify(error)}`);
        
        // Revert changes in profile
        await supabase.from('profiles').update({
            email: previousEmail
        }).eq('id: ', userData.id); 
        
        return;
    }

    f7.views.current.router.back();
    await logoutUser();
}

export async function updateUserName(firstName, lastName){
    const userData = await getUserData();
    const id = userData.id
    const { error } = await supabase
    .from('profiles')
    .update({
        first_name : firstName,
        last_name : lastName
    })
    .eq('id', id);

    if (error) {
        f7.dialog.alert(`There was an error updating your user name. ${JSON.stringify(error)}`);
    } else {
        updateUserData({data : {...userData, first_name: firstName, last_name: lastName}});
    }
}

export async function getUserPlanBucketName(){
    const planResponse = await supabase.from('plan').select('id');
    return planResponse.data[0].id;
}

export async function initBucket(){
    const bucketName = await getUserPlanBucketName();

    const response = await supabase
    .storage
    .getBucket(bucketName);

    if (!response.data) {
        await supabase.storage.createBucket(bucketName);
    }
}

export async function uploadRecipeImage(file) {
    const { data, error } = await supabase
        .storage
        .from('recipes')
        .upload(nanoid(), file, {
            cacheControl: "3600",
            upsert: false,
          });

        if (error) {
            f7.dialog.alert(`There was an error uploading your image. ${error.message}`);
        } else {
            return await getRecipeImage(data.path);
        }
}

export async function getRecipeImage(name) {
    const { data } = await supabase
    .storage
    .from('recipes')
    .getPublicUrl(`${name}`)

    return data.publicUrl;
}

export async function googleLogin(){

    if(window.cordova){
        const { data, error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                queryParams: {
                    access_type: 'offline',
                    prompt: 'consent',
                    response_type: 'id_token',
                    nonce : 'zestyplan'
                },
                skipBrowserRedirect: true
            }
        })
          
        /* whatever your method of redirecting in a popup is */
        cordova.InAppBrowser.open(data.url, '_system');
    } else {
        const { error } = supabase.auth.signInWithOAuth({
            provider: 'google',
          })  
          
        if (error) {
            f7.dialog.alert(`There was an error signing in with Google. ${error}`);
        }
    }
}

export async function facebookLogin() {
    const { data, error } = supabase.auth.signInWithOAuth({
        provider: 'facebook',
      })  
      
    if (error) {
        f7.dialog.alert(`There was an error signing in with Facebook. ${error}`);
    }
}

export async function appleLogin() {

    if(f7.device.cordova){
        // Login with Cordova-based sign in with apple approach
        window.cordova.plugins.SignInWithApple.signin(
            { requestedScopes: [0, 1] },
            async function(succ){
              console.log(succ)
              try {
                const { data, error } = await supabase.auth.signInWithIdToken({
                    provider: 'apple',
                    token: succ.identityToken,
                })
    
                if(!error){
                    f7.loginScreen.close()
                    await initUser();
                    const { data: { user } } = await supabase.auth.getUser();
    
                    if (user) {
                        await initPlanForUser();
                        await initBucket();
                        await fetchUserPlan();
                    }
                } else {
                    throw new Error(error)
                }
                
              } catch(error){
                throw new Error(error)
              }
            },
            function(error){
              console.log(error)
              console.log(JSON.stringify(error))
              throw new Error(error)
            }
        )
    } else {
        // Login with browser-based Sign in with Apple
        const { data, error } = supabase.auth.signInWithOAuth({
            provider: 'apple',
          })  
          
        if (error) {
            f7.dialog.alert(`There was an error signing in with Apple. ${error}`);
        }
    }
}

export async function uploadProfileImage(file) {
    const { data, error } = await supabase
        .storage
        .from('profile-images')
        .upload(nanoid(), file, {
            cacheControl: "3600",
            upsert: false,
          });

        if (error) {
            f7.dialog.alert(`There was an error uploading your image. ${error.message}`);
        } else {
            const userData = await getUserData();
            const id = userData.id
            
            const updateProfile = await supabase
                .from('profiles')
                .update({
                    avatar_url: data.path
                })
                .eq('id', id);

            if (updateProfile.error) {
                f7.dialog.alert(`There was an error uploading your image. ${error.message}`);
            } else {
                updateUserData({data : {...userData, avatar_url: data.path}})
                return await getProfileImage(data.path);
            }
        }
}

export async function getProfileImage(name) {
    const { data } = await supabase
    .storage
    .from('profile-images')
    .getPublicUrl(`${name}`)

    return data.publicUrl;
}

export async function getRecipesForUser(){
    let thisData = await supabase
    .from('recipe')
    .select(`*,
      recipe_ingredients(
        name,
        quantity,
        unit,
        order
      ),
      recipe_steps(
        description,
        order
      ),
      recipe_ratings(
        personal_rating,
        difficulty_rating
      )
    `);

    return thisData;
}

export async function removeRecipeForUser(id) {
    let thisData = await supabase
    .from('recipe')
    .delete()
    .eq('id', id);

    return thisData;
}

export async function getUserLocation(){
    const response = await supabase.functions.invoke(`userLocation`, {
        method: "GET"
    });
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey)
